import React from 'react'
import styled from 'styled-components'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import Hero from 'components/hero'
import NavButton from 'components/common/nav-button'
import Layout from 'components/layout'
import TextContainer from 'components/common/text-container'
import { Heading } from 'components/common/typography'
import testimonials from 'data/testimonials.json'
import desseresImage from 'images/featured/deserres.png'
import bridalAffairImage from 'images/featured/bridal-affair.png'
import realAsianImage from 'images/featured/real-asian.jpg'
import ultimateWeddingImage from 'images/featured/ultimate-wedding.png'
import intimateWeddingsImage from 'images/featured/intimate-weddings.jpg'
import brideCaImage from 'images/featured/bride-ca.jpg'
import munaLunchiImage from 'images/featured/muna-luchi.png'
import { primaryBoldWeight } from 'components/theme/typography'

const Testimonial = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`

const QuotationMark = styled.div`
  font-size: 102px;
`

const Quotation = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${QuotationMark}:first-child {
    margin-right: 8px;

    @media (min-width: 750px) {
      margin-right: 32px;
    }
  }

  ${QuotationMark}:last-child {
    margin-left: 8px;

    @media (min-width: 750px) {
      margin-left: 32px;
    }
  }
`

const Author = styled.div`
  text-align: right;
  font-weight: ${primaryBoldWeight};

  &::before {
    content: '— ';
  }
`

const ViewMoreContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

const Clients = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  img {
    margin: 0 16px;
  }

  @media (max-width: 750px) {
    margin-bottom: 32px;

    img {
      margin-bottom: 12px;
    }
  }
`

class TestimonialsPage extends React.Component {
  state = { numberOfVisibleTestimonials: 3 }

  render() {
    const { data } = this.props
    const { numberOfVisibleTestimonials } = this.state

    return (
      <Layout>
        <Helmet
          title='Testimonials | Pinya Letters'
          meta={[
            {
              name: 'description',
              content:
                'Pinya Letters has worked with a number of clients, both big and small, with virtually all positive things to say!',
            },
          ]}
        />
        <Hero file={data.heroImage} />
        <TextContainer>
          <Heading>Testimonials</Heading>
          <p>Featured on/select clients:</p>
          <Clients>
            <a
              href='https://www.eventsource.ca/blog/a-2019-wedding-open-house-at-twist-gallery'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img src={brideCaImage} width='110' alt="Seen on Today's Bride.ca" />
            </a>
            <a
              href='https://www.todaysbride.ca/articles/romantic-poetic-wedding-style/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img
                src='https://www.eventsource.ca/widgets/blog/es-blog-badge-2019.png'
                width='100'
                alt='Blog on EventSource.ca'
              />
            </a>
            <a
              href='https://ultimatewedding.digital/2019/01/letters-of-love'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img alt='Seen in Ultimate Wedding blog' src={ultimateWeddingImage} width='100' />
            </a>
            <a
              href='http://www.munaluchibridal.com/wedding-ideas-from-munaserenade-toronto-canada/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img alt='Featured in Muna Luchi' src={munaLunchiImage} width='110' />
            </a>
            <a
              href='https://www.intimateweddings.com/blog/love-letters-styled-shoot/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <img alt='Featured in Intimate Weddings' src={intimateWeddingsImage} width='100' />
            </a>
            <img alt='Feature in Bridal affair magazine' src={bridalAffairImage} width='150' />
            <img alt='Live event at Deserres' src={desseresImage} width='150' />
            <img
              alt='Feature in Real asian international film festival'
              src={realAsianImage}
              width='150'
            />
          </Clients>
          <br />
          <p>Here is what some of my clients have to say about my work!</p>
          {testimonials
            .slice(0, numberOfVisibleTestimonials)
            .map(({ description, author }, index) => (
              <Testimonial key={index}>
                <Quotation>
                  <QuotationMark>“</QuotationMark>
                  {description}
                  <QuotationMark>”</QuotationMark>
                </Quotation>
                <Author>{author}</Author>
              </Testimonial>
            ))}
          {numberOfVisibleTestimonials < testimonials.length && (
            <ViewMoreContainer>
              <NavButton
                onClick={() =>
                  this.setState({ numberOfVisibleTestimonials: numberOfVisibleTestimonials + 3 })
                }
                border
                invert
              >
                View More
              </NavButton>
            </ViewMoreContainer>
          )}
        </TextContainer>
      </Layout>
    )
  }
}

export const query = graphql`
  query TestimonialsPageQuery {
    heroImage: file(name: { eq: "hero9" }) {
      ...HeroFile
    }
  }
`

export default TestimonialsPage
