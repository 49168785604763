import styled from 'styled-components'

const TextContainer = styled.div`
  max-width: 720px;
  width: 100%;
  padding: 0 16px;
  margin-top: 30px;
`

export default TextContainer
