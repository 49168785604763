import styled from 'styled-components'

import { gray } from 'components/theme/colours'
import { secondaryBoldWeight, secondaryFont } from 'components/theme/typography'

export const Heading = styled.h1`
  letter-spacing: 1px;
  font-family: ${secondaryFont};
  font-weight: ${secondaryBoldWeight};
  line-height: 1.5;
  font-size: 32px;
  color: ${gray};
  margin: 16px 0;
`

export const SubHeading = styled.h2`
  letter-spacing: 1px;
  line-height: 1.5;
  font-family: ${secondaryFont};
  font-weight: ${secondaryBoldWeight};
  font-size: 22px;
  color: ${gray};
  margin: 16px 0;
`
