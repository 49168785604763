import React from 'react'
import styled from 'styled-components'

import TextContainer from 'components/common/text-container'
import Logo from 'components/common/logo'
import { secondaryFont } from 'components/theme/typography'
import { gray, yellow } from 'components/theme/colours'
import facebookImage from 'images/social/facebook.svg'
import instagramImage from 'images/social/instagram.svg'
import emailImage from 'images/social/email.svg'

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  width: 100%;
  background-color: ${yellow};
  height: 100%;
  min-height: 240px;
  color: ${gray};
  font-family: ${secondaryFont};

  a {
    color: #393d3f;
  }
`

const Address = styled.address`
  font-style: normal;
`

const SocialIcon = styled.img`
  height: 32px;
  width: 32px;
  margin-right: 8px;
`

export default function Footer() {
  return (
    <FooterContainer>
      <TextContainer>
        <Logo gray />
        <br />
        Toronto Calligrapher servicing the GTA
        <br />
        <Address>
          <a href='mailto:hello@pinyaletters.com'>hello@pinyaletters.com</a>
        </Address>
        <br />
        <a href='mailto:hello@pinyaletters.com'>
          <SocialIcon src={emailImage} />
        </a>
        <a href='https://www.instagram.com/pinyaletters/'>
          <SocialIcon src={instagramImage} />
        </a>
        <a href='https://www.facebook.com/pinyaletters'>
          <SocialIcon src={facebookImage} />
        </a>
      </TextContainer>
    </FooterContainer>
  )
}
