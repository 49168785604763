import React from 'react'
import styled, { css } from 'styled-components'
import { Link } from 'gatsby'

import { gray, transparentGray } from 'components/theme/colours'
import NavButton from 'components/common/nav-button'
import Logo from 'components/common/logo'
import hamburgerImage from 'images/hamburger.svg'
import closeImage from 'images/close.svg'

const Banner = styled.nav`
  min-height: 60px;
  max-height: 60px;
  width: 100%;
  background-color: ${props => (props.solid ? gray : transparentGray)};
  padding: 0 8px;

  display: flex;
  top: 0;
  left: 0;
  z-index: 3;
  position: fixed;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`

const LogoLink = styled(Link)`
  ${props =>
    props.isNavigation
      ? css`
          margin-bottom: 30px;
        `
      : css`
          margin-right: auto;
          margin-left: 8px;
        `}

  display: flex;
  align-items: center;
  justify-content: center;
`

const NavigationToggle = styled.img.attrs(({ isOpen }) => ({
  src: isOpen ? closeImage : hamburgerImage,
}))`
  cursor: pointer;
  display: none;
  padding: 8px;
  z-index: 2;

  ${props =>
    props.isOpen &&
    css`
      position: fixed;
    `}

  @media (max-width: 750px) {
    display: block;
  }
`

const DesktopNavigation = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 750px) {
    display: none;
  }
`

const MobileNavigation = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  background-color: ${gray};
  width: 100%;
  height: 100%;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
`

const LINKS = [
  {
    to: '/contact',
    label: 'Get a Quote',
  },
  {
    to: '/testimonials',
    label: 'Testimonials',
  },
  {
    to: '/blog',
    label: 'Blog',
  },
  {
    to: '/portfolio',
    label: 'Portfolio',
    border: true,
  },
]

function NavLinks() {
  return LINKS.map(({ to, label, border }) => {
    return (
      <Link key={to} to={to}>
        <NavButton border={border}>{label}</NavButton>
      </Link>
    )
  })
}

export default class Navigation extends React.Component {
  state = { isMobileNavigationOpen: false }

  render() {
    const { className, solidNav } = this.props
    const { isMobileNavigationOpen } = this.state

    return (
      <Banner className={className} solid={solidNav}>
        <LogoLink to='/'>
          <Logo />
        </LogoLink>
        {isMobileNavigationOpen && (
          <MobileNavigation onClick={() => this.setState({ isMobileNavigationOpen: false })}>
            <NavLinks />
            <LogoLink to='/' isNavigation>
              <Logo />
            </LogoLink>
          </MobileNavigation>
        )}
        <DesktopNavigation>
          <NavLinks />
        </DesktopNavigation>
        <NavigationToggle
          isOpen={isMobileNavigationOpen}
          onClick={() => this.setState({ isMobileNavigationOpen: !isMobileNavigationOpen })}
        />
      </Banner>
    )
  }
}
