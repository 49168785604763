import React from 'react'
import styled, { css } from 'styled-components'

import { regularWeight, secondaryFont } from 'components/theme/typography'
import { yellow, gray } from 'components/theme/colours'

const StyledButton = styled.button`
  border: none;
  text-align: center;
  outline: 0;
  user-select: none;
  min-height: 39px;
  max-height: 39px;
  font-family: ${secondaryFont};
  font-size: 14px;
  font-weight: ${regularWeight};
  background: none;
  text-transform: uppercase;
  letter-spacing: 4px;
  color: ${props => (props.invert ? gray : 'white')};
  cursor: pointer;
  padding: 0 16px;

  @media (max-width: 750px) {
    margin-top: 12px;
    color: ${props => (props.invert ? gray : 'yellow')};
  }

  ${props =>
    props.border &&
    css`
    border-width: 2px;
    border-style: solid;
    border-color: ${props => (props.invert ? gray : 'white')}
    border-radius: 0px;

    @media (max-width: 750px) {
      margin-top: 12px;
      border-color: ${props => (props.invert ? gray : yellow)}
    }
  `}
`

export default function NavButton(props) {
  return <StyledButton {...props} />
}
