import React, { createRef, Component } from 'react'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

import Navigation from 'components/navigation'

const Container = styled.div`
  width: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const StyledImg = styled(Img).attrs(({ offset }) => ({
  style: {
    transform: `translate3d(0, ${offset}px, 0)`,
  },
}))`
  width: 100vw;

  @media (min-width: 1026px) {
    height: 600px;
  }

  @media (max-width: 750px) {
    height: 300px;
  }
`
const Parallax = styled.div`
  display: block;
  width: 100vw;
  overflow: hidden;

  @media (min-width: 1026px) {
    max-height: 600px;
  }

  @media (max-width: 750px) {
    max-height: 300;
  }
`

export default class Hero extends Component {
  heroRef = createRef()

  state = { offset: 0 }

  componentDidMount = () => {
    document.addEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    if (!this.heroRef.current || !this.heroRef.current.imageRef.current) return

    const imageRef = this.heroRef.current.imageRef.current
    const visiblePixels = imageRef.getBoundingClientRect().bottom
    if (visiblePixels < 0) return

    const offset = Math.max(0, (imageRef.clientHeight - visiblePixels) * 0.4)
    window.requestAnimationFrame(() => this.setState({ offset }))
  }

  render() {
    const { solidNav, file } = this.props
    const { offset } = this.state

    return (
      <Container>
        <Navigation solidNav={solidNav} />
        <Parallax>
          <StyledImg
            fluid={file.childImageSharp.fluid}
            alt='Sample image from portfolio'
            ref={this.heroRef}
            offset={offset}
          />
        </Parallax>
      </Container>
    )
  }
}

export const fragment = graphql`
  fragment HeroFile on File {
    id

    childImageSharp {
      fluid(maxHeight: 600, quality: 75) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`
