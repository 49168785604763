import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import styled, { createGlobalStyle } from 'styled-components'
import styledNormalize from 'styled-normalize'

import Footer from 'components/footer'
import global from 'components/theme/global'

const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  margin: 0 auto;
`

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
  ${global}
`

const Layout = ({ children }) => (
  <Container>
    <Helmet
      title='Toronto Modern Calligraphy | Pinya Letters'
      meta={[
        {
          name: 'description',
          content:
            'Pinya Letters is a Toronto based calligraphy business that caters to weddings, in-store art, events, and Tagalog merchandise.',
        },
        { name: 'keywords', content: 'toronto, weddings, tagalog, calligraphy, affordable' },
        {
          name: 'google-site-verification',
          content: 'gBnJvV2ydgX8WnY2c-V0cAp9Ze19qaXyvYfLSRP3O3I',
        },
      ]}
    >
      <html lang='en-ca' />
      <script type='application/ld+json'>
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'Person',
          name: 'Pinya Letters',
          url: 'https://pinyaletters.com',
          sameAs: [
            'https://www.facebook.com/pinyaletters',
            'https://www.instagram.com/pinyaletters/',
          ],
        })}
      </script>
      <script type='application/ld+json'>
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          url: 'https://pinyaletters.com',
          logo: 'https://pinyaletters.com/static/logo-gray.png',
        })}
      </script>
    </Helmet>
    <GlobalStyle />
    {children}
    <Footer />
  </Container>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
