import React from 'react'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'

export default function Logo({ large, gray }) {
  return (
    <StaticQuery
      query={graphql`
        query LogoQuery {
          grayLogoImage: file(name: { eq: "logo-gray" }) {
            id
            childImageSharp {
              fixed(width: 200, quality: 90) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }

          whiteLogoImage: file(name: { eq: "logo" }) {
            id
            childImageSharp {
              fixed(width: 150, quality: 90) {
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <Img
          fixed={data[gray ? 'grayLogoImage' : 'whiteLogoImage'].childImageSharp.fixed}
          alt='Pinya Letters calligraphy-style logo'
        />
      )}
    />
  )
}
