import { css } from 'styled-components'

import { primaryFont, regularWeight, primaryBoldWeight } from './typography'
import { gray } from './colours'

export default css`
  html,
  body {
    margin: 0;
    padding: 0;
    font-family: ${primaryFont};
    font-weight: ${regularWeight};
    font-size: 15px;
    line-height: 24px;
    color: ${gray};
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    font-weight: ${primaryBoldWeight};
    color: ${gray};
  }
`
